/*
* ===================================================
*    Maps
* ===================================================
*/

.map-wrapper-450 {
    height: 450px;
}

.map-wrapper-300 {
    height: 300px;
}

.map-full {
    &.shadow-left, &.shadow-right {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            z-index: 1000;
            pointer-events: none;
        }
    }
    &.shadow-left::before {
        box-shadow: inset .5rem 0 1rem -.5rem rgba($black, .15)
    }
    &.shadow-right::before {
        box-shadow: inset .5rem 0 1rem -.5rem rgba($black, .15)
    }
}

@media print {
    .map-full {
        height: 500px;
        page-break-before: always !important;
    }
}

#map {
    width: 100%;
    height: 1000px;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .map-side#{$infix} {
        .map-full {
            height: 100vh;
            min-height: 400px;
            z-index: 5;
        }

        @include media-breakpoint-up($next) {
            position: fixed;
            top: $navbarHeight;
            right: 0;

            .map-full {
                height: calc(100vh - #{$navbarHeight});
            }
        }
    }
}

.map-custom-popup {

    .leaflet-popup-content-wrapper {
        overflow: hidden;
        padding: 0;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        font-family: $font-family-base;
        font-size: 0.8rem;
        line-height: 1.5;
    }

    .leaflet-popup-content {
        margin: 0;
        background: #fff;
    }

    .leaflet-popup-tip {
        background: #fff;
        box-shadow: none;
    }

    a.leaflet-popup-close-button {
        display: inline-block;
        width: 30px !important;
        height: 30px !important;
        padding: 0 !important;
        text-align: center;
        vertical-align: middle;
        text-decoration: none;
        color: #fff !important;
        background: map-get($theme-colors, 'dark') !important;
        font-weight: bold;
        line-height: 30px !important;
    }

    .leaflet-popup-content {
        p {
            margin: 0 0 1rem;
        }

        a {
            color: $link-color;
        }
    }

    .popup-venue {
        display: flex;

        .image {
            width: 200px;
            background-position: center center;
            background-size: cover;
        }

        .text {
            width: 400px;
            padding: 1rem;
        }
    }

    .popup-rental {
        .image {
            width: 100%;
            height: 200px;
            background-position: center center;
            background-size: cover;
        }

        .text {
            width: 300px;
            padding: 1rem;
        }
    }

}

.map-custom-tooltip {
    padding: .5rem;
    border: 1px solid #fff;
    border-radius: 3px;
    background-color: #fff;
    font-family: $font-family-base;
    font-weight: bold;

    &.active {
        color: #fff;
        border-color: map-get($theme-colors,"primary");
        background-color: map-get($theme-colors,"primary");

        &:before {
            border-top-color: map-get($theme-colors,"primary");
        }
    }
}