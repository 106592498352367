/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/

/*
   Text utils 
   ------------------------
*/

.text-uppercase {
    @include uppercase;
}

.text-serif {
    font-family: $font-family-serif !important;
}

.text-shadow {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
    text-shadow: 2px 2px 2px rgba(map-get($theme-colors, "primary"), .1);
}

.mb-30px {
    margin-bottom: $grid-gutter-width;
}

/*
    Border - 0 for table cells
   ------------------------
*/

.border-0 {

    >th,
    >td {
        border: none !important;
    }
}

/*
    Star checkbox
   ------------------------
*/

.form-star {

    label {
        position: relative;
        margin-bottom: 0;
        cursor: pointer;
        color: $gray-300;
        height: 1.2em;

        &::before {
            content: "";
            display: inline-block;
            vertical-align: text-bottom;
            width: 1.4em;
            height: 1.4em;
            background-image: escape-svg(url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img"><title>Star</title><desc>A line styled icon from Orion Icon Library.</desc><path d="M32 48L15 58l4-19L5 26l19-2 8-18 8 18 19 2-14 13 4 19-17-10z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke="#{$gray-300}" fill="#{$gray-300}" data-name="layer1" stroke-linejoin="round"/></svg>'));
        }
    }

    >input:checked~label::before {
        background-image: escape-svg(url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img"><title>Star</title><desc>A line styled icon from Orion Icon Library.</desc><path d="M32 48L15 58l4-19L5 26l19-2 8-18 8 18 19 2-14 13 4 19-17-10z" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke="#{$warning}" fill="#{$warning}" data-name="layer1" stroke-linejoin="round"/></svg>'));
    }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
}

/*
     Colours and backgrounds
    ------------------------
*/

@each $color,
$value in $grays {
    .bg-gray-#{$color} {
        background: $value !important;
    }

    .text-gray-#{$color} {
        color: $value;
    }
}

.bg-primary-100 {
    background: $primary-100;
}

@each $color,
$value in $theme-colors {
    .bg-#{$color}-light {
        background-color: tint-color($value, 85%);
    }
}

@each $color,
$value in $theme-colors {
    .badge-#{$color}-light {
        color: $value;
        background-color: tint-color($value, 85%)
    }
}

.table-hover {
    tbody tr.no-stripe {
        --#{$variable-prefix}table-accent-bg: $white;
    }
}

.table-hover {
    tbody tr.no-hover {
        &:hover {
            background-color: transparent !important;
        }
    }
}

/*
     Transparent overlays
    ------------------------
*/

.light-overlay,
.dark-overlay,
.gradient-overlay {
    position: relative;
    overflow: hidden;

    .overlay-content {
        position: relative;
        z-index: 20;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: ' ';
        opacity: .3;
        background: #000;
    }
}

.light-overlay {
    &::after {
        opacity: .3;
        background: #fff;
    }
}

.gradient-overlay {
    &::after {
        opacity: 1;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 78%, rgba(0, 0, 0, 0.65) 100%);
    }
}

/*
     Other
    ------------------------
*/

.overflow-visible {
    overflow: visible !important;
}

.overflow-y-visible {
    overflow-y: visible !important;
}

.shadow-0 {
    box-shadow: none !important;
}

.left-box {
    box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.7);
}

.hover-scale {
    transition: $transition-base;

    &:hover {
        transform: scale(1.05);
    }
}

.hover-animate {
    transition: $transition-base;

    &:hover,
    &:focus {
        transform: translate3d(0, -2px, 0);
    }
}

.hover-scale-bg-image {
    .bg-image {
        transition: $transition-base;
    }

    &:hover,
    &:focus {
        .bg-image {
            transform: scale(1.05);
        }
    }
}

.tile-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 30;
}

.close-absolute {
    position: absolute;
    top: 0;
    right: 0;
}

.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

img.bg-image {
    font-family: 'object-fit: cover;';
    object-fit: cover;
}

.bg-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.full-screen {
    height: calc(100vh - #{$navbarHeight});
}

.mh-full-screen {
    min-height: 100vh;

    // hack for IE 11
    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
        height: 100vh;
    }
}

.list-group {
    border-radius: $list-group-border-radius
}

@media print {

    .shadow-print-0 {
        box-shadow: none !important;
    }

    .px-print-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .p-print-0 {
        padding: 0 !important;
    }

}

// fixes for IE11
@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {

    .card {
        display: block;
    }

    .card-img-top,
    .card-img-bottom,
    .card-img {
        width: auto;
        min-width: 100%;
        height: auto;
    }


}